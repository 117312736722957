import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TrackingSearch from "../components/molecules/TrackingSearch";
import MainLayouts from "../layouts/MainLayouts";
import { Block } from "../components/atoms/UI/Block";
import { CheckActiveIcons } from "../components/atoms/Icons";
import styled from "@emotion/styled";
import { useTypedSelector } from "../hooks/useTypedSelector";
import useDebounced from "../hooks/useDebounced";

const Tracking = () => {
  const location = useLocation(); // Получаем текущий URL
  const [search, setSearch] = useState<string>("");
  const [showTrackings, setShowTrackings] = useState<boolean>(false);
  const searchDebounced: string = useDebounced(search, 500);
  const invoice = useTypedSelector((state) => state.profile.invoice);

  // Получаем значение параметра "number" из URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const numberFromURL = searchParams.get("number");

    if (numberFromURL) {
      setSearch(numberFromURL); // Устанавливаем 
    }
  }, [location.search]); // Выполняется при изменении URL

  return (
    <MainLayouts title="Мои посылки">
      <div className="title">Мои посылки</div>

      <div className="tracking">
        <TrackingSearch
          onSearch={setSearch}
          search={search}
          searchDebouced={searchDebounced}
          setShowTrackings={setShowTrackings}
          invoice={invoice}
        />

        {showTrackings && invoice.Statuses && (
          <div className="tracking_show">
            <div className="title">
              Статус доставки:{" "}
              <span style={{ color: "#02447F" }}>
                {invoice?.Statuses?.at(-1)?.status}
              </span>
            </div>
            {invoice.Statuses?.map((item, index) => (
              <TrackShow
                className="flex items-center content-between"
                key={index + 1}
              >
                <div className="flex items-center gap-1_2 status">
                  <CheckActiveIcons />
                  <div className="subtitle-block">
                    {item.date.split(" ")[0]}
                  </div>
                  <div className="subtitle-block">№{searchDebounced}</div>
                </div>

                <div className="subtitle-block">{item.status}</div>
              </TrackShow>
            ))}
          </div>
        )}
      </div>
    </MainLayouts>
  );
};

const TrackShow = styled(Block)`
  margin-bottom: 1.6rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 480px) {
    svg {
      width: 16px;
      height: 16px;
    }

    .gap-1_2 {
      gap: 1.2rem;

      .subtitle-block {
        font-size: 1.2rem;
      }
    }

    flex-wrap: wrap;
  }
`;

export default Tracking;
